import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Table,
  Button,
  Alert,
  Accordion,
  Card,
  Col,
} from "react-bootstrap";
import _ from "lodash";

import {
  getAllLivestreams,
  updateLivestream,
  deleteLivestream,
  addLivestream,
} from "../../../actions";
import Loading from "../../loading";

const defaultNew = {
  stream_id: "",
  uiconf_id: "",
  entry_id: "",
  available: 0,
  category: "",
  isLoading: false,
  error: "Unable to add livestream. Please try again.",
  hasError: false,
  validated: false,
};
class LivestreamAdmin extends Component {
  constructor(props) {
    super(props);
    this.newStreamForm = React.createRef();
    this.state = {
      streams: [],
      new: defaultNew,
    };
  }

  componentDidMount = async () => {
    if (!this.props.live.list) {
      await this.props.getAllLivestreams();
      const list = _.cloneDeep(this.props.live.list);
      this.setState({
        streams: await this.createStatefulList(list),
      });
    } else if (this.props.live.list && this.state.streams.length === 0) {
      const list = _.cloneDeep(this.props.live.list);
      this.setState({
        streams: await this.createStatefulList(list),
      });
    }
  };

  createStatefulList(list) {
    return list.map((item) => {
      const newItem = _.cloneDeep(item);
      newItem.changed = false;
      newItem.isLoading = false;
      return newItem;
    });
  }

  createTable() {
    const rows = this.state.streams.map((row, i) => {
      return (
        <tr key={i}>
          <td>
            <Form.Control
              type="text"
              value={
                this.state.streams[i].stream_id
                  ? this.state.streams[i].stream_id
                  : ""
              }
              onChange={(e) => this.onCellChange(i, "stream_id", e)}
              disabled={this.state.streams[i].isLoading}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              value={
                this.state.streams[i].uiconf_id
                  ? this.state.streams[i].uiconf_id
                  : ""
              }
              onChange={(e) => this.onCellChange(i, "uiconf_id", e)}
              disabled={this.state.streams[i].isLoading}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              value={
                this.state.streams[i].entry_id
                  ? this.state.streams[i].entry_id
                  : ""
              }
              onChange={(e) => this.onCellChange(i, "entry_id", e)}
              disabled={this.state.streams[i].isLoading}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              value={
                this.state.streams[i].category
                  ? this.state.streams[i].category
                  : ""
              }
              onChange={(e) => this.onCellChange(i, "category", e)}
              disabled={this.state.streams[i].isLoading}
            />
          </td>
          <td>
            <Form.Control
              as="select"
              value={this.state.streams[i].available ? "Yes" : "No"}
              onChange={(e) => this.onCellChange(i, "available", e)}
              disabled={this.state.streams[i].isLoading}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Control>
          </td>
          <td>
            <Button
              disabled={
                !this.state.streams[i].changed ||
                this.state.streams[i].isLoading
              }
              variant="primary"
              onClick={() => this.updateSession(this.state.streams[i].id)}
            >
              {this.state.streams[i].isLoading ? "Loading..." : "Update"}
            </Button>
          </td>
          <td>
            <Button
              disabled={this.state.streams[i].isLoading}
              variant="danger"
              onClick={() => this.deleteSession(this.state.streams[i].id)}
            >
              {this.state.streams[i].isLoading ? "Loading..." : "Delete"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <>
        <Table striped responsive="sm">
          <thead>
            <tr>
              <th>Stream ID</th>
              <th>UIconf ID</th>
              <th>Entry ID</th>
              <th>Category</th>
              <th>Available</th>
              <th colSpan="2"></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </>
    );
  }

  addSessionTable = () => {
    const row = (
      <>
        <Form.Row>
          <Form.Group as={Col} xs controlId="stream_id">
            <Form.Label>Stream ID</Form.Label>
            <Form.Control
              type="text"
              value={this.state.new.name}
              onChange={(e) => this.onNewCellChange("stream_id", e)}
              disabled={this.state.new.isLoading}
              placeholder="Stream ID"
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs controlId="uiconf_id">
            <Form.Label>UIconf ID</Form.Label>
            <Form.Control
              type="text"
              value={this.state.new.name}
              onChange={(e) => this.onNewCellChange("uiconf_id", e)}
              disabled={this.state.new.isLoading}
              placeholder="UIconf ID"
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs controlId="entry_id">
            <Form.Label>Entry ID</Form.Label>
            <Form.Control
              type="text"
              value={this.state.new.name}
              onChange={(e) => this.onNewCellChange("entry_id", e)}
              disabled={this.state.new.isLoading}
              placeholder="Entry ID"
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs controlId="livestream_available">
            <Form.Label>Available</Form.Label>
            <Form.Control
              as="select"
              value={this.state.new.available ? "Yes" : "No"}
              onChange={(e) => this.onNewCellChange("available", e)}
              disabled={this.state.new.isLoading}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs controlId="livestream_category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              value={this.state.new.name}
              onChange={(e) => this.onNewCellChange("category", e)}
              disabled={this.state.new.isLoading}
              placeholder="Category"
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Button
            variant="success"
            disabled={this.state.new.isLoading}
            className="d-block mx-auto"
            type="submit"
          >
            {this.state.new.isLoading ? "Loading..." : "Add Livestream"}
          </Button>
        </Form.Row>
      </>
    );
    return (
      <>
        <Form
          noValidate
          validated={this.state.new.validated}
          onSubmit={this.submitForm}
          ref={this.newStreamForm}
        >
          {row}
        </Form>
        {this.state.new.hasError && (
          <Alert variant="danger">{this.state.new.error}</Alert>
        )}
      </>
    );
  };

  updateSession = async (id) => {
    const tempState = [...this.state.streams];
    const sess = tempState.filter((stream) => stream.id === id)[0];
    sess.isLoading = !sess.isLoading;
    this.setState({ streams: tempState });
    delete sess.changed;
    delete sess.isLoading;
    await this.props.updateLivestream(sess);

    const list = [...this.props.live.list];
    let index = list.findIndex((item) => item.id === sess.id);
    const updatedSession = list[index];
    updatedSession.changed = false;
    updatedSession.isLoading = false;

    let tempIndex = tempState.findIndex((i) => i.id === updatedSession.id);
    tempState[tempIndex] = updatedSession;
    this.setState({ streams: tempState });
  };

  deleteSession = async (id) => {
    const tempState = [...this.state.streams];
    const tempStateIndex = tempState.findIndex((i) => i.id === id);
    tempState[tempStateIndex].isLoading = true;
    this.setState({ streams: tempState });
    await this.props.deleteLivestream(id);

    const list = [...this.props.live.list];
    let index = list.findIndex((item) => item.id === id);
    if (index === -1) tempState.splice(tempStateIndex, 1);
    this.setState({ streams: tempState });
  };

  submitForm = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    const tempNewState = { ...this.state.new };
    tempNewState.validated = true;
    this.setState({ new: tempNewState });

    if (form.checkValidity() === true) {
      await this.addSession(e);
    }
  };

  addSession = async (e) => {
    const tempState = [...this.state.streams];
    const newSession = { ...this.state.new };
    newSession.isLoading = true;
    this.setState({ new: newSession });
    await this.props.addLivestream(newSession);

    const list = [...this.props.live.list];
    if (list.length > tempState.length) {
      const addedSession = list[list.length - 1];
      addedSession.isLoading = false;
      addedSession.changed = false;
      tempState.push(addedSession);
      this.newStreamForm.current.reset();
      this.setState({ new: defaultNew, streams: tempState });
    } else {
      newSession.hasError = true;
      newSession.isLoading = false;
      this.setState({ new: newSession });
    }
  };

  onCellChange = (row, key, e) => {
    const currState = this.state.streams;
    let value;
    if (e.target.value === "Yes" || e.target.value === "No") {
      value = e.target.value === "Yes" ? 1 : 0;
    } else {
      value = e.target.value;
    }
    currState[row][key] = value;
    currState[row].changed = true;
    this.setState({ streams: currState });
  };

  onNewCellChange = (key, e) => {
    const tempState = { ...this.state.new };
    if (key === "available") {
      tempState[key] = e.target.value === "Yes" ? 1 : 0;
    } else {
      tempState[key] = e.target.value;
    }
    this.setState({ new: tempState });
  };

  render() {
    return (
      <>
        <h2 className="text-left mb-3">Livestream Admin</h2>
        {this.props.live.list ? (
          <Accordion>
            <Card className="mb-0">
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h4>Manage Current Livestreams</h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>{this.createTable()}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h4>Add New Livestream</h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>{this.addSessionTable()}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { live: state.live };
};

export default connect(mapStateToProps, {
  getAllLivestreams,
  updateLivestream,
  deleteLivestream,
  addLivestream,
})(LivestreamAdmin);
