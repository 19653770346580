import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Jumbotron,
  Accordion,
  Card,
} from "react-bootstrap";

import Layout from "../layout";
import { getAllLivestreams } from "../../actions";
import Loading from "../loading";

class LivestreamPage extends Component {
  componentDidMount = async () => {
    if (!this.props.live.list) {
      await this.props.getAllLivestreams();
    }
  };
  createList(list) {
    const types = {};
    list
      .filter((i) => i.available)
      .forEach((item) => {
        if (!item.category) item.category = "General Livestreams";
        if (item.category in types) {
          types[item.category].push(item);
        } else {
          types[item.category] = [item];
        }
      });
    const cols = Object.keys(types)
      .sort()
      .map((type, i) => {
        return this.createListGroup(types[type], i);
      });
    const rows = [];
    const chunk = 1;
    for (let i = 0, j = 0; i < cols.length / chunk; i++) {
      const row = [];
      while (j < (i + 1) * chunk) {
        row.push(cols[j] ? cols[j] : <Col sm key={j}></Col>);
        j++;
      }
      rows.push([<Row key={i}>{row}</Row>]);
    }
    return rows;
  }
  createListGroup(list, i) {
    return (
      <Col sm key={i}>
        <h4 className="mb-3 mt-3">{list[0].category}</h4>
        <ListGroup className="mt-3">
          {list
            .filter((item) => item.available)
            .map((item) => {
              return (
                <ListGroup.Item key={item.id}>
                  <a
                    href={`/livestream/${item.id}`}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Livestream {item.id}
                  </a>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Col>
    );
  }
  render() {
    return (
      <Layout title="Livestreams">
        <Jumbotron>
          <h4>
            Please check the observation calendar to see when streams will be
            available.
          </h4>
          <br />
          Initial signature reviews have been completed and there is no
          additional data to observe online related to voter records. Remote
          observation of the post election operations continues, as noted below. To view
          additional voter record reviews during the final phase there are
          options for in-person observation -{" "}
          <a
            href="https://ocvote.com/observe"
            alt="Election Observation Signups"
            rel="noopener noreferrer"
            target="_blank"
          >
            click here for more information.
          </a>
          <br />
          <br />
          To view system requirements, click the dropdown below.
          <br />
          <br />
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                System Requirements ▼
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {" "}
                  Minimum requirements for viewing:
                  <ul>
                    <li>Windows 7 or higher, Mac OS X 10.6 or higher</li>
                    <li>
                      Supported browsers:
                      <ul>
                        <li>Google Chrome 45+</li>
                        <li>Mozilla Firefox 49+</li>
                        <li>Safari 10+</li>
                        <li>Microsoft Edge 15+</li>
                      </ul>
                    </li>
                    <li>
                      Supported mobile browsers:
                      <ul>
                        <li>Chrome 45+</li>
                        <li>Safari 10+</li>
                      </ul>
                    </li>
                    <li>
                      Connection speed: 5-10mbps download speed minimum is
                      recommended for smooth playback Livestream feeds links are
                      posted below by category.
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          {this.props.live.list ? (
            this.createList([...this.props.live.list])
          ) : (
            <Loading />
          )}
        </Jumbotron>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { live: state.live };
};

export default connect(mapStateToProps, { getAllLivestreams })(LivestreamPage);
