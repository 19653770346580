import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "../layout";
import Login from "./login";
import WebexAdmin from "./webex/index.js";
import LivestreamAdmin from "./livestream/index.js";

class Admin extends Component {
  render() {
    return (
      <Layout title="Admin Portal">
        {!this.props.auth.isSignedIn && <Login />}
        {this.props.auth.isAdmin && <WebexAdmin />}
        {this.props.auth.isAdmin && <LivestreamAdmin />}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(Admin);
