import {
  GET_LIVESTREAM,
  GET_ALL_LIVESTREAMS,
  UPDATE_LIVESTREAM,
  ADD_LIVESTREAM,
  DELETE_LIVESTREAM,
} from "../actions/types";

const INITIAL_STATE = {
  list: null,
  stream_id: null,
  uiconf_id: null,
  entry_id: null,
  path: null,
  pageTitle: "Livestream",
  available: false,
  category: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LIVESTREAM:
      return {
        ...state,
        stream_id: action.payload["stream_id"],
        uiconf_id: action.payload["uiconf_id"],
        entry_id: action.payload["entry_id"],
        path: action.payload.path,
        pageTitle: action.payload.pageTitle,
        available: action.payload.available,
        category: action.payload.category
          ? "General Livestreams"
          : action.payload.category,
      };
    case GET_ALL_LIVESTREAMS:
    case ADD_LIVESTREAM:
    case UPDATE_LIVESTREAM:
    case DELETE_LIVESTREAM:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};
