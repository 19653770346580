import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import "./App.scss";
import LivestreamList from "../components/livestream_list";
import Livestream from "../components/livestream";
import Home from "../components/home";
import Logout from "../components/logout";
import Calendar from "../components/calendar";
import Webex from "../components/webex";
import Admin from "../components/admin";
import ProtectedRoute from "./auth/ProtectedRoute";
import { reopenSession } from "../actions";
import userPool from "./auth/UserPool";
import history from "../history";
import NotFound from "./FourOneFour";

class App extends Component {
  constructor(props) {
    super(props);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
          history.push("/");
        } else {
          const jwt = session.getIdToken().jwtToken;
          this.props.reopenSession(jwt);
        }
      });
    } else if (window.location.pathname !== "/admin") {
      history.push("/");
    }
  }

  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Home}></Route>
            <Route path="/calendar" exact component={Calendar}></Route>
            <ProtectedRoute
              path="/logout"
              exact
              component={Logout}
            ></ProtectedRoute>
            <ProtectedRoute
              path="/livestream"
              exact
              component={LivestreamList}
            ></ProtectedRoute>
            <ProtectedRoute
              path="/livestream/:id"
              exact
              component={Livestream}
            ></ProtectedRoute>
            <ProtectedRoute
              path="/webex"
              exact
              component={Webex}
            ></ProtectedRoute>
            <Route
              path="/admin"
              exact
              component={Admin}
            ></Route>
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { reopenSession })(App);
