export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const REOPEN_SESSION = "REOPEN_SESSION";
export const GET_LIVESTREAM = "GET_LIVESTREAM";
export const GET_ALL_LIVESTREAMS = "GET_ALL_LIVESTREAMS";
export const UPDATE_LIVESTREAM = "UPDATE_LIVESTREAM";
export const ADD_LIVESTREAM = "ADD_LIVESTREAM";
export const DELETE_LIVESTREAM = "DELETE_LIVESTREAM";
export const GET_ALL_WEBEX_SESSIONS = "GET_ALL_WEBEX_SESSIONS";
export const UPDATE_WEBEX_SESSION = "UPDATE_WEBEX_SESSION";
export const ADD_WEBEX_SESSION = "ADD_WEBEX_SESSION";
export const DELETE_WEBEX_SESSION = "DELETE_WEBEX_SESSION";
