import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { signOut } from "../../actions";
import userPool from "../auth/UserPool";
import "./logout.scss";

class Logout extends Component {
  logOut = (e) => {
    e.preventDefault();
    const cognitoUser = userPool.getCurrentUser();
    this.props.signOut(cognitoUser);
  };
  render() {
    return (
      <Link className="nav-link logout" onClick={this.logOut} to="/">
        Sign Out
      </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { signOut })(Logout);
