import jwtDecode from "jwt-decode";
import { aws } from "../api";
import history from "../history";

import {
  SIGN_IN,
  SIGN_OUT,
  REOPEN_SESSION,
  GET_LIVESTREAM,
  GET_ALL_LIVESTREAMS,
  GET_ALL_WEBEX_SESSIONS,
  UPDATE_WEBEX_SESSION,
  DELETE_WEBEX_SESSION,
  ADD_WEBEX_SESSION,
  UPDATE_LIVESTREAM,
  DELETE_LIVESTREAM,
  ADD_LIVESTREAM,
} from "./types";

export const signIn = (cognitoUser, cognitoUserSession) => async (dispatch) => {
  cognitoUser.setDeviceStatusRemembered({
    onSuccess: function (result) {},
    onFailure: function (err) {
      console.log(err.message || JSON.stringify(err));
    },
  });
  const jwt = cognitoUserSession.getIdToken().getJwtToken();
  const jwtInfo = jwtDecode(jwt);
  const groups = jwtInfo["cognito:groups"];
  const username = jwtInfo["cognito:username"];
  let isAdmin = false;
  if (groups) {
    if (groups.includes("Administrators")) isAdmin = true;
  }
  isAdmin ? history.push("/admin") : history.push("/");
  dispatch({
    type: SIGN_IN,
    payload: { username, isAdmin, jwt },
  });
};

export const reopenSession = (jwt) => {
  let isAdmin = false;
  const jwtInfo = jwtDecode(jwt);
  const groups = jwtInfo["cognito:groups"];
  const username = jwtInfo["cognito:username"];
  if (groups) {
    groups.includes("Administrators") ? (isAdmin = true) : (isAdmin = false);
  }
  return {
    type: REOPEN_SESSION,
    payload: { username, isAdmin, jwt },
  };
};

export const signOut = (cognitoUser) => async (dispatch) => {
  cognitoUser.signOut();
  history.push("/logout");
  dispatch({
    type: SIGN_OUT,
  });
};

export const getAllWebexSessions = () => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const res = await aws({
    method: "GET",
    url: "/webex",
    headers: {
      Authorization: jwt,
    },
  });
  let data =
    res.data.statusCode === 200 ? JSON.parse(res.data.body) : res.data.body;
  dispatch({ type: GET_ALL_WEBEX_SESSIONS, payload: data });
};

export const updateWebexSession = (row) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const { list } = currState.webex;
  const res = await aws({
    method: "PUT",
    url: `/webex/admin/${row.id}`,
    headers: {
      Authorization: jwt,
    },
    data: JSON.stringify(row),
  });
  if (res.status === 200) {
    const data = res.data;
    let index = list.findIndex((item) => item.id === data.id);
    list[index] = data;
  }
  dispatch({ type: UPDATE_WEBEX_SESSION, payload: list });
};

export const deleteWebexSession = (id) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const { list } = currState.webex;
  const res = await aws({
    method: "DELETE",
    url: `/webex/admin/delete`,
    headers: {
      Authorization: jwt,
      id: id,
    },
  });
  if (res.status === 200) {
    let index = list.findIndex((item) => item.id === id);
    list.splice(index, 1);
  }
  dispatch({ type: DELETE_WEBEX_SESSION, payload: list });
};

export const addWebexSession = (session) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const { list } = currState.webex;
  const res = await aws({
    method: "POST",
    url: `/webex/admin/add`,
    headers: {
      Authorization: jwt,
    },
    data: JSON.stringify(session),
  });
  if (res.status === 200) {
    const newSession = {
      id: res.data.id,
      link: res.data.link,
      name: res.data.name,
      available: res.data.available,
      category: res.data.category,
    };
    list.push(newSession);
  }
  dispatch({ type: ADD_WEBEX_SESSION, payload: list });
};

export const getLivestream = (id) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const res = await aws({
    method: "GET",
    url: "/livestream",
    headers: {
      Authorization: jwt,
      id: id,
    },
  });
  let data;
  res.data.statusCode === 200
    ? (data = JSON.parse(res.data.body))
    : (data = res.data.body);
  data.path = id;
  data.pageTitle = `Livestream ${id}`;
  dispatch({ type: GET_LIVESTREAM, payload: data });
};

export const getAllLivestreams = () => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const res = await aws({
    method: "GET",
    url: "/livestream/all",
    headers: {
      Authorization: jwt,
    },
  });
  let data =
    res.data.statusCode === 200 ? JSON.parse(res.data.body) : res.data.body;
  dispatch({ type: GET_ALL_LIVESTREAMS, payload: data });
};

export const updateLivestream = (row) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const { list } = currState.live;
  const res = await aws({
    method: "PUT",
    url: `/livestream/admin/${row.id}`,
    headers: {
      Authorization: jwt,
    },
    data: JSON.stringify(row),
  });
  if (res.status === 200) {
    const data = res.data;
    let index = list.findIndex((item) => item.id === data.id);
    list[index] = data;
  }
  dispatch({ type: UPDATE_LIVESTREAM, payload: list });
};

export const deleteLivestream = (id) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const { list } = currState.live;
  const res = await aws({
    method: "DELETE",
    url: `/livestream/admin/delete`,
    headers: {
      Authorization: jwt,
      id: id,
    },
  });
  if (res.status === 200) {
    let index = list.findIndex((item) => item.id === id);
    list.splice(index, 1);
  }
  dispatch({ type: DELETE_LIVESTREAM, payload: list });
};

export const addLivestream = (session) => async (dispatch, getState) => {
  const currState = getState();
  const { jwt } = currState.auth;
  const { list } = currState.live;
  const res = await aws({
    method: "POST",
    url: `/livestream/admin/add`,
    headers: {
      Authorization: jwt,
    },
    data: JSON.stringify(session),
  });
  if (res.status === 200) {
    const newSession = {
      id: res.data.id,
      stream_id: res.data.stream_id,
      uiconf_id: res.data.uiconf_id,
      entry_id: res.data.entry_id,
      category: res.data.category,
      available: res.data.available,
    };
    list.push(newSession);
  }
  dispatch({ type: ADD_LIVESTREAM, payload: list });
};
