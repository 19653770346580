import { combineReducers } from "redux";
import authReducer from "./authReducer";
import livestreamReducer from "./livestreamReducer";
import webexReducer from "./webexReducer";

export default combineReducers({
  auth: authReducer,
  live: livestreamReducer,
  webex: webexReducer,
});
