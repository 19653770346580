import React from "react";

import Layout from "../layout";
const Calendar = () => {
  return (
    <Layout title="Election Observation Calendar">
      <div className="embed-responsive embed-responsive-1by1">
        <iframe
          className="embed-responsive-item"
          frameBorder="0"
          scrolling="no"
          src="https://calendar.google.com/calendar/embed?src=vqkju20hedbth9liue4h12r87c%40group.calendar.google.com&amp;ctz=America%2FLos_Angeles"
          style={{ border: "0" }}
          title="Google Calendar"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Calendar;
