import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Jumbotron, Row, Col } from "react-bootstrap";

import Layout from "../layout";
import Login from "../login";
import About from "../about";

class HomePage extends Component {
  render() {
    return (
      <Layout title="OC ROV Remote Observation System">
        {this.props.auth.isSignedIn ? (
          <>
            <Jumbotron>
              Below are remote conferencing links displaying observable
              activities. Our videoconferencing system requires the use of
              WebEx, which is freely available. You may be prompted to download
              WebEx if it is not installed on your system.
              <br />
              <Link to="/calendar" alt="Election Observation Calendar">
                To view an updated schedule of observable activities, click
                here.
              </Link>
              <br />
              The schedule will be updated daily at a minimum. To view a screen
              share and video capture at the same time,{" "}
              <a
                href="https://help.webex.com/en-us/2kpmz0/Full-Screen-Content-Sharing-Views-in-Cisco-Webex-Meetings-and-Cisco-Webex-Events-Slow-Channel#reference_093292D2C631398F6842154C0324C883"
                target="_blank"
                rel="noopener noreferrer"
              >
                follow the WebEx guide here.
              </a>
            </Jumbotron>
            <Row>
              <Col sm className="mb-3">
                <Button as={Link} to="/webex" block>
                  Webex Sessions
                </Button>
              </Col>
              <Col sm className="mb-3">
                <Button as={Link} to="/livestream" block>
                  Livestreams
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <About />
            <Login />
          </>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(HomePage);
