import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import userPool from "./UserPool";

class ProtectedRoute extends Component {
  render() {
    const { component, ...rest } = this.props;
    const Component = component;
    let isAuthenticated = false;
    const currUser = userPool.getCurrentUser();
    if (currUser) {
      currUser.getSession((err, session) => {
        if (err) console.log(err);
        else {
          isAuthenticated = true;
        }
      });
    }
    return isAuthenticated ? (
      <Component {...rest} />
    ) : (
      <Redirect to={{ pathname: "/" }} />
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(ProtectedRoute);
