import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../layout";

const NotFound = () => {
  return (
    <Layout title="Page Not Found">
      <Alert variant="danger" className="text-center w-50 d-block mx-auto">
        <h1 className="display-2">404</h1>
        <Link to="/" alt="Home">
          Click here to go home
        </Link>
      </Alert>
    </Layout>
  );
};

export default NotFound;
