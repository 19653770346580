import {
  GET_ALL_WEBEX_SESSIONS,
  UPDATE_WEBEX_SESSION,
  ADD_WEBEX_SESSION,
  DELETE_WEBEX_SESSION,
} from "../actions/types";

const INITIAL_STATE = {
  list: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_WEBEX_SESSIONS:
    case UPDATE_WEBEX_SESSION:
    case ADD_WEBEX_SESSION:
    case DELETE_WEBEX_SESSION:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};
