import React, { Component } from "react";
import { connect } from "react-redux";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Form, Button, Row, Col } from "react-bootstrap";

import UserPool from "../auth/UserPool";
import history from "../../history";

import { signIn } from "../../actions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.passwordInput = React.createRef();
    this.state = {
      username: "public",
      password: "",
      errors: "",
      hasSubmit: false,
    };
  }

  renderLogin() {
    if (!this.props.auth.isSignedIn) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Form.Group as={Row} className="mb-5">
            <Form.Label column sm={2}>
              Password
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter password"
                value={this.state.password}
                onChange={this.onInputChange}
                ref={this.passwordInput}
                autoFocus
              />
            </Col>
            {this.state.errors && (
              <span className="alert alert-danger d-block mx-auto mt-3">
                {this.state.errors}
              </span>
            )}
          </Form.Group>
          {!this.state.hasSubmit ? (
            <Button
              variant="primary"
              type="submit"
              className="d-block mx-auto"
              size="lg"
            >
              Submit
            </Button>
          ) : (
            <Button className="d-block mx-auto" disabled size="lg">
              Loading...
            </Button>
          )}
        </Form>
      );
    } else {
      return (
        <>
          <h2>You are already signed in!</h2>
          <br />
          <Button
            onClick={history.goBack}
            className="d-block mx-auto"
            size="lg"
          >
            Go Back
          </Button>
        </>
      );
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ hasSubmit: true });
    const user = new CognitoUser({
      Username: this.state.username,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: this.state.username,
      Password: this.state.password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        user.getSession((err, session) => {
          if (err) {
            console.error(err);
            history.push("/");
          }
          this.props.signIn(user, session);
        });
      },

      onFailure: (err) => {
        this.setState({
          errors: err.message,
          username: "",
          password: "",
          hasSubmit: false,
        });
        this.passwordInput.current.focus();
      },
    });
  };
  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return this.renderLogin();
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { signIn })(Login);
