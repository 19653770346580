import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import IconImg from "../../img/oclogo-small.png";
import Logout from "../logout";
import "./header.scss";

class Header extends Component {
  renderHeader = () => {
    const { isSignedIn, isAdmin } = this.props.auth;
    return (
      <Navbar.Collapse id="pass-nav">
        <Nav className="mr-auto">
          <span>
            <Link
              to="/"
              className={
                this.props.title === "OC ROV Remote Observation System"
                  ? "active nav-link"
                  : "nav-link"
              }
            >
              Home
            </Link>
          </span>

          {isSignedIn && (
            <span>
              <Link
                to="/webex"
                className={
                  this.props.title === "Webex Links"
                    ? "active nav-link"
                    : "nav-link"
                }
              >
                Webex Links
              </Link>
            </span>
          )}

          {isSignedIn && (
            <span>
              <Link
                to="/livestream"
                className={
                  this.props.title.toLowerCase().includes("livestream")
                    ? "active nav-link"
                    : "nav-link"
                }
              >
                Livestreams
              </Link>
            </span>
          )}

          <span>
            <Link
              className={
                this.props.title === "Election Observation Calendar"
                  ? "active nav-link"
                  : "nav-link"
              }
              to="/calendar"
            >
              Calendar
            </Link>
          </span>
        </Nav>
        {(isAdmin) && (
          <span>
            <Link
              to="/admin"
              className={
                this.props.title === "Admin Portal"
                  ? "active nav-link navButton"
                  : "nav-link navButton"
              }
            >
              Admin
            </Link>
          </span>
        )}
        {isSignedIn && <Logout />}
      </Navbar.Collapse>
    );
  };

  render() {
    return (
      <header>
        <Navbar bg="dark" variant="dark" expand="lg" className="mb-3">
          <Link to="/" alt="VC PASS Home">
            <Navbar.Brand>
              <img src={IconImg} alt="Logo"></img>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="pass-nav" />
          {this.renderHeader()}
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(Header);
