import React from "react";
import { Container } from "react-bootstrap";
import Helmet from "react-helmet";

import Header from "../header";
import Footer from "../footer";

const Layout = ({ title, children }) => {
  return (
    <div>
      <Helmet>
        <title>{`Election Observation | ${title}`}</title>
      </Helmet>
      <Header title={title} />
      <Container className="mt-4 mb-5">
        <h1 className="display-4 mb-5">{title}</h1>
        <div style={{ marginBottom: "5rem" }}>{children}</div>
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;
