import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Jumbotron, ListGroup, Row, Col } from "react-bootstrap";

import Layout from "../layout";
import { getAllWebexSessions } from "../../actions";
import Loading from "../loading";

class Webex extends Component {
  componentDidMount = async () => {
    if (!this.props.webex.list) {
      this.props.getAllWebexSessions();
    }
  };
  createList(list) {
    const types = {};
    list
      .filter((i) => i.available)
      .forEach((item) => {
        if (item.category in types) {
          types[item.category].push(item);
        } else {
          types[item.category] = [item];
        }
      });
    const cols = Object.keys(types)
      .sort()
      .map((type, i) => {
        return this.createListGroup(types[type], i);
      });
    const rows = [];
    const chunk = 1;
    for (let i = 0, j = 0; i < cols.length / chunk; i++) {
      const row = [];
      while (j < (i + 1) * chunk) {
        row.push(cols[j] ? cols[j] : <Col sm key={j}></Col>);
        j++;
      }
      rows.push([<Row key={i}>{row}</Row>]);
    }
    return rows;
  }
  createListGroup(list, i) {
    return (
      <Col sm key={i}>
        <h4 className="mb-3 mt-3">{list[0].category}</h4>
        <ListGroup className="mt-3">
          {list
            .filter((item) => item.available)
            .map((item) => {
              return (
                <ListGroup.Item key={item.id}>
                  <a
                    href={`${item.link}`}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Webex Session {item.id}
                  </a>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Col>
    );
  }
  render() {
    return (
      <Layout title="Webex Links">
        <Jumbotron>
          <h4>
            Please check the observation calendar to see when sessions will be
            available.
          </h4><br />
          Below are remote conferencing links displaying observable activities.
          Our videoconferencing system requires the use of WebEx, which is
          freely available. You may be prompted to download WebEx if it is not
          already installed on your system. <br />
          <a
            href="https://help.webex.com/en-us/2kpmz0/Full-Screen-Content-Sharing-Views-in-Cisco-Webex-Meetings-and-Cisco-Webex-Events-Slow-Channel#reference_093292D2C631398F6842154C0324C883"
            alt="Webex Guide"
            rel="noopener noreferrer"
            target="_blank"
          >
            To view a screen share and video capture at the same time, follow
            the WebEx guide here.
          </a>
          <br />
          <Link to="/calendar" alt="Election Observation Calendar">
            To view an updated schedule of observable activities, click here.
          </Link>
          {this.props.webex.list ? (
            this.createList(this.props.webex.list)
          ) : (
            <Loading />
          )}
        </Jumbotron>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { webex: state.webex };
};

export default connect(mapStateToProps, { getAllWebexSessions })(Webex);
