import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "../layout";
import { getLivestream } from "../../actions";
import Loading from "../loading";

class Livestream extends Component {
  componentDidMount = async () => {
    const path = this.props.location.pathname.split("/");
    const id = path[path.length - 1];
    this.props.getLivestream(id);
  };

  renderPlayer() {
    const src = `https://mediaplatform.streamingmediahosting.com/p/10807/sp/1080700/embedIframeJs/uiconf_id/6710347/partner_id/10807?iframeembed=true&playerId=smh_player&entry_id=${this.props.live.entry_id}&flashvars[LeadHLSOnAndroid]=true`;
    return (
      <div className="embed-responsive embed-responsive-4by3 mb-3">
        <div className="d-block mx-auto" id="vid_player">
          <iframe
            id="smh_player"
            src={src}
            allowFullScreen
            allow="autoplay *; fullscreen *; encrypted-media *"
            title={this.props.live.pageTitle}
          ></iframe>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout title={this.props.live.pageTitle}>
        {this.props.live ? this.renderPlayer() : <Loading />}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { live: state.live };
};

export default connect(mapStateToProps, { getLivestream })(Livestream);
